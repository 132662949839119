import { Language } from "../types/types";

interface VariousNames {
  companyName: string,
  title: string,
  description: string,
  faq: string;
  descriptionFaq: string;
  descriptionCont: string;
  footer: string;
  articleFooter: string;
  articleFooterPpl: string;
  adress: string;
  adressContent: string;
  email: string;
  details: string;
  detailsContent: string;
  phone: string;
  contacts: string;
  contactUs: string;
  sitemap: string;
  nextCourses: string;
}

const variousNames: Record<Language, VariousNames> = {
  lv: {
    companyName: "ABC mācību centrs",
    title: "ABC mācību centrs - metināšanas kursi",
    description:"ABC mācību un konsultāciju centrs piedāvā metināšanas kursus Rīgā. Iegūsti sertifikātu un praktiskas iemaņas pieredzējušu pasniedzēju vadībā.",
    faq: "Biežāk uzdotie jautājumi",
    descriptionFaq: "Biežāk uzdotie jautājumi par metināšanu.",
    descriptionCont: "Sazinieties ar ABC Mācību centru Rīgā, lai iegūtu papildu informāciju par metināšanas kursiem, reģistrētos vai uzdotu jautājumus.",
    footer:
      "© 2024  Mācību un konsultāciju centrs ABC | Izstrādājusi Dace Troice",
    articleFooter:
      "Sīkāka informācija pa tālruni: 20248447 vai e-pastā: info@metinasana.lv",
    articleFooterPpl:
      "Sīkāka informācija pa tālruni: 20248447 vai e-pastā: info@ppl.lv",
    adress: "Adrese",
    adressContent: "Krišjāņa Barona iela 130 k-2, Rīga",
    email: "E-pasts",
    details: "Rekvizīti",
    detailsContent: " Reģ. Nr.: 45403009746 Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012",
    phone: "Tālr.",
    contacts: "Kontakti",
    contactUs: "Sazinies ar mums",
    sitemap: "Lapas karte",
    nextCourses: "Nākošie kursi sāksies",
  },
  en: {
    companyName: "ABC Training Centre",
    title: "ABC Training Center - Welding Courses",
    description:"ABC Training and Consulting Center offers welding courses in Riga. Obtain a certificate and gain practical skills under the guidance of experienced instructors.",
    faq: "Frequently Asked Questions",
    descriptionFaq: "Frequently asked questions about welding.",
    descriptionCont:"Contact ABC Training Center in Riga for more information about welding courses, to register, or to ask any questions.",
    footer:
      "© 2024  Mācību un konsultāciju centrs ABC | Developed by Dace Troice | All Rights Reserved",
    articleFooter:
      "For more information, call: 20248447 or email: info@metinasana.lv",
    articleFooterPpl:
      "For more information, call: 20248447 or email: info@ppl.lv",
    adress: "Address",
    adressContent: "Kr. Barona Street 130, k-2, Riga",
    email: "Email",
    details: "Details",
    detailsContent: " Reģ. Nr.: 45403009746 Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012",
    phone: "Phone",
    contacts: "Contacts",
    contactUs: "Contact Us",
    sitemap: "Site Map",
    nextCourses: "Next courses will start",
  },
  ru: {
    companyName: "ABC учебный центр",
    title: "Учебный центр ABC - Курсы сварки",
    description:"Учебный и консультационный центр ABC предлагает курсы сварки в Риге. Получите сертификат и практические навыки под руководством опытных преподавателей.",
    faq: "Часто задаваемые вопросы",
    descriptionFaq: "Часто задаваемые вопросы о сварке.",
    descriptionCont:"Свяжитесь с ABC учебным центром в Риге, чтобы получить дополнительную информацию о курсах сварки, зарегистрироваться или задать вопросы.",
    footer:
      "© 2024  Mācību un konsultāciju centrs ABC | Разработалa Dace Troice",
    articleFooter:
      "Дополнительную информацию можно получить по телефону: 20248447 или по электронной почте: info@metinasana.lv",
    articleFooterPpl:
      "Дополнительную информацию можно получить по телефону: 20248447 или по электронной почте: info@ppl.lv",
    adress: "Адрес",
    adressContent: "Ул. Кр. Барона 130, k-2, Рига",
    email: "Эл. почта",
    details: "Реквизиты",
    detailsContent: " Reģ. Nr.: 45403009746 Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012",
    phone: "Тел.",
    contacts: "Контакты",
    contactUs: "Свяжитесь с нами",
    sitemap: "Карта сайта",
    nextCourses: "Следующие курсы начнутся",
  },
};

export default variousNames;

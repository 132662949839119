import React, { useContext } from "react";
import { Helmet } from 'react-helmet';

import Accordion from "../components/Accordion";
import { LanguageContext } from "../App";
import variousNames from "../utils/variousNames";

const Sertification: React.FC = () => {

  const { language } = useContext(LanguageContext);
  const varNames = variousNames[language];
  return (
    <>
    {/* Helmet component */}
        <Helmet>
          <title>{varNames.faq} - {varNames.companyName}</title>
          <meta name="description" content={varNames.descriptionFaq} />
          
        </Helmet>
      <header>
        <h2>{varNames.faq}</h2>
      </header>
      <Accordion />
    </>
  );
};

export default Sertification;

import React, { useContext }from "react";
import { LanguageContext } from "../App";
import variousNames from "../utils/variousNames";
import { Helmet } from 'react-helmet';

import GoogleMap from "../components/GoogleMap";
import mapImage from "../assets/images/map.jpg";
import schoolEntrance from "../assets/images/abc-ieeja.jpg";
import InputForm from "../components/InputForm";

const Home: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const varNames = variousNames[language];

  return (
    <>
    {/* Helmet component */}
            <Helmet>
              <title>{varNames.contacts} - {varNames.companyName}</title>
              <meta name="description" content={varNames.descriptionCont} />
              
            </Helmet>
      <header>
        <h2>{varNames.contacts}</h2>
      </header>
      <div className="main-container">
      <div className="flex-container">
       
        <div className="contact-item" id="item1">
          <p>
            <strong>{varNames.email}: </strong>{" "}
            <a href="mailto:info@metinasana.lv">info@metinasana.lv</a>
          </p>
          <p>
            <strong>{varNames.phone}: </strong>{" "}
            <a href="tel:+371 20248447">+371 20248447</a>
          </p>
          <p>
            <strong>{varNames.adress}: </strong>Krišjāņa Barona iela 130 k-2, Rīga,
            LV-1012
          </p>
          <p>
            <strong>{varNames.details}: </strong>Reģ. Nr.: 45403009746
            <br />
            Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012
          </p>
          <img className="contacts-img" src={schoolEntrance} width="80%" alt="skolas ieeja" />
        </div>
        <div className="contact-item" id="item2">
          <img className="contacts-map" src={mapImage} width="90%" alt="karte" />
        </div>
        <div className="contact-item" id="item3">
          <h2>{varNames.contactUs}</h2>
         <InputForm formType="contacts"/>
        </div>
        <div className="contact-item" id="item4">
         
          
          <GoogleMap/>
        </div>
      </div>
      </div>
    </>
  );
};

export default Home;
